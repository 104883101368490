<template>
  <td class="text-center">
    <q-img
      v-if="product_value.Logo"
      :src="product_value.Logo"
      spinner-color="white"
      style="min-width: 100px; max-width: 100px"
    />
    <h5
      v-else
      style="min-width: 100px; max-width: 100px; text-transform: capitalize"
      class="q-my-none q-mx-auto"
    >
      {{ product_value.brand }}
    </h5>
  </td>
  <td class="text-center" style="white-space: initial">
    {{ product_value.product_naam }}
  </td>
  <td class="text-center">
    <div
      style="white-space: initial"
      v-html="product_value.product_omschrijving"
    ></div>
  </td>
  <td class="text-center" style="white-space: initial">
    {{ product_value.frequency }}
  </td>
  <td class="text-center">
    <q-btn
      :disabled="readonly"
      size="12px"
      style="width: 120px"
      :color="
        findSelectedProduct(product_key, product_value.aanbieding_id)
          ? 'negative'
          : 'primary'
      "
      :label="
        findSelectedProduct(product_key, product_value.aanbieding_id)
          ? 'Deselecteer'
          : 'Kiezen'
      "
      no-caps
      @click="checkPriceType({ id: product_key, product: product_value })"
    />
  </td>
  <td
    style="width: 40px"
    v-if="
      findProductPrice(product_key, product_value) &&
      (product_value.price_type == 'manual'
        ? findProductPrice(product_key, product_value)
        : true)
    "
  >
    <h5 class="text-weight-bolder text-center q-ma-none text-grey-9">
      {{
        new Intl.NumberFormat("nl-NL", {
          style: "currency",
          currency: "EUR",
          maximumFractionDigits: 0,
        }).format(findProductPrice(product_key, product_value))
      }}
    </h5>
  </td>
  <td
    style="width: 40px; padding-left: 0% !important"
    v-if="
      findProductPrice(product_key, product_value) &&
      product_value.price_type == 'manual' &&
      findProductPrice(product_key, product_value)
    "
  >
    <q-btn @click="dialog = true" dense color="primary" icon="edit" />
  </td>

  <q-dialog v-model="dialog" persistent>
    <manual-price
      :min_value="product_value.min_value"
      :max_value="product_value.max_value"
      :max_year_value="product_value.max_year_value"
      :calculation_type="product_value.calculation_type"
      :frequency="product_value.frequency"
      @emitAmount="(amount) => setAmount(amount)"
    />
  </q-dialog>
</template>

<script setup>
import { defineAsyncComponent } from "vue";
import { ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { delete_product, set_product } from "@/plugins/products.js";
import { useQuasar } from "quasar";
const ManualPrice = defineAsyncComponent(() =>
  import("@/components/MultiOffer/Offers/ManualPrice.vue")
);
//* Props
// eslint-disable-next-line no-undef
const props = defineProps({
  product_value: Object,
  product_key: Number || String,
});

//* VueUse
const $store = useStore();
const $q = useQuasar();

//* Refs
const dialog = ref(false);

//* Computed
const selectedProducts = computed(() => $store.getters.selectedProducts ?? []);
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);

//*  Methods
function checkPriceType(product) {
  if (product.product?.price_type != "fixed") {
    if (findSelectedProduct(product.id)) {
      product.product.product_prijs_incl = null;
      setProduct(product);
      return;
    }
    dialog.value = true;
    return;
  }

  setProduct(product);
}

function setProduct(product) {
  if (!findSelectedProduct(product.id)) {
    clearProducts(product.id);
    set_product(product);
  } else {
    console.log(product.id);
    delete_product(product.id);
  }
}

function findSelectedProduct(key, aanbieding_id) {
  return selectedProducts.value.find(
    (arr) => arr.product_id == key && arr.product.aanbieding_id == aanbieding_id
  );
}

function findProductPrice(product_key, product_value) {
  if (findSelectedProduct(product_key, product_value.aanbieding_id)) {
    const selectedProduct = JSON.parse(
      JSON.stringify(
        findSelectedProduct(product_key, product_value.aanbieding_id)
      )
    );
    return selectedProduct.product.product_prijs_incl;
  }

  return product_value.product_prijs_incl;
}

function setAmount(amount) {
  const tmp_product_value = props.product_value;
  tmp_product_value.product_prijs_incl = amount;

  clearProducts(props.product_key);
  set_product({ id: props.product_key, product: tmp_product_value });
  dialog.value = false;
  $q.notify({
    type: "positive",
    message: "Bedrag opgeslagen",
    position: "top",
  });
}

function clearProducts(product_id) {
  selectedProducts.value.forEach((product) => {
    if (product.product_id == product_id) return;
    delete_product(product.product_id);
  });
}

//* Conditions
if (selectedProducts.value) {
  //   get_all_offers();

  $store.dispatch("setProjectSpecificValue", {
    key: "redo_offer",
    value: false,
  });
}
</script>
<style lang="sass"></style>
